body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    font-size: 16px;
    text-align: justify;
  }

  .section-content h1 {
    font-weight: bold;
  }

  .titre-fond-blanc-citation {
    background-color:white!important;
    font-size:14px;
    padding:5px 10px;
    margin:15px -25px
   }
   .resp-titre-fond-transparent {
    font-size:21px;
    color:#000;
    font-weight:400 !important;
    border-left:5px solid #000;
    padding-left:10px;
    text-transform:uppercase;
    font-weight:600;
   }
   .resp-titre-fond-blanc {
    font-size:20px;
    font-size:16px;
    color:#000;
    background:#fff;
    display:inline-block;
    padding:5px 10px;
    border-left:5px solid #000;
    font-weight:600;
    text-transform:uppercase!important;
   }

   .info {
     background-color: gainsboro;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
             justify-content: center;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     margin-top: 30px;
   }

   .infoContainer {
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-align-items: center;
             align-items: center;
   }




.textureconfessionnal {
  background-image: url(/static/media/texture9.9d0f9306.jpg);
}

.texturedefault {
  background-image: url(/static/media/texture2.044a0322.jpg);
}

