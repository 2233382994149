.section-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    font-size: 16px;
    text-align: justify;
  }

  .section-content h1 {
    font-weight: bold;
  }

  .titre-fond-blanc-citation {
    background-color:white!important;
    font-size:14px;
    padding:5px 10px;
    margin:15px -25px
   }
   .resp-titre-fond-transparent {
    font-size:21px;
    color:#000;
    font-weight:400 !important;
    border-left:5px solid #000;
    padding-left:10px;
    text-transform:uppercase;
    font-weight:600;
   }
   .resp-titre-fond-blanc {
    font-size:20px;
    font-size:16px;
    color:#000;
    background:#fff;
    display:inline-block;
    padding:5px 10px;
    border-left:5px solid #000;
    font-weight:600;
    text-transform:uppercase!important;
   }

   .info {
     background-color: gainsboro;
     display: flex;
     justify-content: center;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     margin-top: 30px;
   }

   .infoContainer {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

